<template>
  <v-row justify="center">
    <v-app>
      <v-dialog
        v-model="sharePanel"
        :width="
          $vuetify.breakpoint.mdAndDown
            ? setSmallScreenStyle()
            : $vuetify.breakpoint.lgOnly
            ? setMediumScreenStyle()
            : setLargeScreenStyle()
        "
        class="custom-font-family"
        persistent
      >
        <v-card class="mk-share-panel-header-card">
          <v-row class="pa-0 ma-0 mk-share-panel-header-section">
            <v-col
              cols="12"
              class="ma-0 pr-0 mk-share-panel-header-title-section"
            >
              <v-row class="pa-0 ma-0">
                <v-col cols="12" class="pa-0 ma-0">
                  <h4 class="pa-0 ma-0 mk-share-panel-title text-truncate">
                    {{ assignmentToShare.title }}
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <submission-alert-message
                ref="banner"
                v-model="showBanner"
                :message="bannerMessage"
              ></submission-alert-message>
            </v-col>
          </v-row>

          <v-row class="pa-0 ma-0 mk-batch-header-title-section">
            <v-col cols="12" lg="4" xl="4" class="pa-0 ma-0">
              <h1 class="pr-0 py-0 ma-0">
                {{ !$language ? "List of Batches" : "ব্যাচ লিস্ট" }}
              </h1>
            </v-col>
            <v-col cols="12" lg="8" xl="8" class="pa-0 ma-0"></v-col>
          </v-row>

          <v-row class="pa-0 ma-0">
            <v-col cols="5" lg="4" xl="4" class="pa-0 ma-0">
              <no-content-component
                v-if="noContentCondition"
                :message="noContentMessage"
              ></no-content-component>
              <v-item-group v-else multiple>
                <v-container class="pa-0 ma-0">
                  <div class="mk-share-batch-section">
                    <v-row class="pa-0 mr-0 my-0 mk-batch-list-item">
                      <v-col
                        v-for="batch in assignmentToShare.batches"
                        :id="'title' + batch.batch_id"
                        :key="batch.batch_id"
                        class="px-0 pb-0 mr-0 my-0"
                        cols="12"
                        @click="showForm(batch.batch_id)"
                      >
                        <v-item>
                          <v-card
                            style="
                              border: thin solid rgba(0, 0, 0, 0.12) !important;
                            "
                            outlined
                            flat
                            :color="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_clicked
                                ? '#BBDEFB'
                                : sharePanelFormData['form' + batch.batch_id]
                                    .should_highlight
                                ? 'primary'
                                : 'white'
                            "
                            class="d-flex align-center white--text mk-share-batch-card"
                          >
                            <v-scroll-y-transition>
                              <div
                                v-if="
                                  sharePanelFormData['form' + batch.batch_id]
                                    .is_clicked
                                "
                                class="flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                              <div
                                v-else-if="
                                  sharePanelFormData['form' + batch.batch_id]
                                    .should_highlight
                                "
                                class="flex-grow-1 text-left text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                              <div
                                v-else
                                class="flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title"
                              >
                                {{ batch.batch_title }}
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-item-group>
            </v-col>
            <v-col
              cols="7"
              lg="8"
              xl="8"
              class="pa-0 ma-0 mk-share-panel-form-section"
            >
              <v-row
                v-for="(batch, index) in assignmentToShare.batches"
                :id="batch.batch_id"
                :key="batch.batch_id"
                class="pa-0 ma-0 mk-share-panel-form"
              >
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pa-0 ma-0"
                  @submit.prevent="submit"
                >
                  <v-row class="pa-0 ma-0 mk-share-panel-form-input-date">
                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="mk-share-panel-alert">
                        <p>Share Date Must Be Before Deadline.</p>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-publish-date-section"
                    >
                      <div>
                        <v-menu
                          v-model="dialogMap['share' + batch.batch_id]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              ref="shareDate"
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .share_date_for_view
                              "
                              outlined
                              :label="
                                !$language ? 'Share Date' : 'তারিখে শেয়ার করুন'
                              "
                              class="mk-date-input-text-field"
                              :placeholder="
                                !$language
                                  ? 'Select Date'
                                  : 'তারিখ নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                $vuetify.breakpoint.lgOnly
                              "
                              rounded-0
                              :rules="[required]"
                              hint="DD/MM/YYYY format"
                              clearable
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .share_date
                            "
                            no-title
                            scrollable
                            @input="
                              hideInputDateField(batch.batch_id, index, 'share')
                            "
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-due-date-section"
                    >
                      <div>
                        <v-menu
                          v-model="dialogMap['due' + batch.batch_id]"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          :disabled="
                            sharePanelFormData['form' + batch.batch_id]
                              .is_static
                              ? true
                              : false
                          "
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              ref="dueDate"
                              v-model="
                                sharePanelFormData['form' + batch.batch_id]
                                  .due_date_for_view
                              "
                              outlined
                              :label="
                                !$language
                                  ? 'Submission Deadline'
                                  : 'জমা দেওয়ার সময়সীমা'
                              "
                              :placeholder="
                                !$language
                                  ? 'Select Date'
                                  : 'তারিখ নির্বাচন করুন'
                              "
                              append-icon="mdi-calendar-month-outline"
                              :rules="
                                sharePanelFormData['form' + batch.batch_id]
                                  .is_static
                                  ? []
                                  : [required]
                              "
                              hint="DD/MM/YYYY format"
                              :dense="
                                $vuetify.breakpoint.mdAndDown ||
                                $vuetify.breakpoint.lgOnly
                              "
                              class="mk-date-input-text-field"
                              clearable
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .due_date
                            "
                            no-title
                            scrollable
                            @input="
                              hideInputDateField(batch.batch_id, index, 'due')
                            "
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section"
                    >
                      <div>
                        <v-text-field
                          v-model="
                            sharePanelFormData['form' + batch.batch_id]
                              .total_score
                          "
                          class="mk-date-input-text-field"
                          outlined
                          :label="!$language ? 'Mark' : 'মার্ক'"
                          :placeholder="!$language ? 'ex:100' : 'উদা: ১০০'"
                          :dense="
                            $vuetify.breakpoint.mdAndDown ||
                            $vuetify.breakpoint.lgOnly
                          "
                          :rules="[required]"
                          @input="shouldEnableShare(batch.batch_id)"
                        ></v-text-field>
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_share
                            "
                            type="checkbox"
                            :disabled="!isAllFieldVerified(batch.batch_id)"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="text"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section"
                    >
                      <div class="d-flex align-center">
                        Is it available publicly?
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_public
                            "
                            type="checkbox"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="public-text"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row
                    class="px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn"
                  >
                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pl-0 py-0 ma-0 mk-total-mark-section"
                    >
                      <div class="d-flex align-center">
                        Is it shared for a limited time?
                      </div>
                    </v-col>

                    <v-col
                      cols="12"
                      lg="6"
                      xl="6"
                      class="pr-0 py-0 ma-0 mk-share-btn-section"
                    >
                      <div>
                        <label class="mk-share-switch">
                          <input
                            v-model="
                              sharePanelFormData['form' + batch.batch_id]
                                .is_static
                            "
                            type="checkbox"
                          />
                          <div class="mk-share-slider"></div>
                          <div class="static-text"></div>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </v-row>
            </v-col>
          </v-row>
          <div class="mk-share-cancle-done-btn-section">
            <v-row class="pa-0 ma-0">
              <div class="pl-0 py-0 ma-0 mk-share-panel-cancel">
                <v-btn
                  depressed
                  tile
                  plain
                  class="custom-font-family"
                  @click="closeSharePanel()"
                >
                  {{ !$language ? "Cancel" : "বাতিল করুন" }}
                </v-btn>
              </div>

              <div class="pa-0 mx-0 mx-0 mk-share-panel-done">
                <v-btn
                  depressed
                  tile
                  plain
                  :loading="should_show_loading_icon"
                  class="custom-font-family"
                  @click="organizeDataAndSend()"
                >
                  {{ !$language ? "Done" : "সম্পন্ন করুন" }}
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-app>
  </v-row>
</template>

<script>
import { FieldValidations } from "/global/utils/validations.js";
import NoContentComponent from "/global/components/NoContentComponent";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";

export default {
  name: "AssignmentSharePanel",
  components: {
    NoContentComponent,
    SubmissionAlertMessage,
  },
  props: {
    assignmentToShare: {
      type: Object,
      required: true,
    },
    sharePanel: Boolean,
    should_show_loading_icon: Boolean,
  },
  mixins: [FieldValidations],
  data: () => ({
    valid: true,
    isStatic: false,
    current_form_element: undefined,
    dialogMap: {},
    sharePanelFormData: {},
    finalFormData: {},
    showBanner: false,
  }),

  created() {
    this.initializeFormData();
  },

  mounted() {
    this.activeFirstBatchForm();
  },

  watch: {
    valid(value){
      if(value) this.showBanner = false;
    }
  },

  computed: {
    bannerMessage() {
      return !this.$language
        ? "Share form for every batch must be valid"
        : "প্রত্যেক ব্যাচ এর ফর্ম সঠিক হতে হবে";
    },
    noContentCondition() {
      return this.assignmentToShare?.batches == 0;
    },
    noContentMessage() {
      return !this.$language ? "There are no batches" : "কোনো ব্যাচ নেই";
    },
  },

  methods: {
    isBefore(start, end) {
      let [d1, m1, y1] = start.split("/");
      let [d2, m2, y2] = end.split("/");
      if (y1 == y2) {
        if (m1 == m2) {
          if (d1 == d2) return false;
          return d1 < d2;
        }
        return m1 < m2;
      }
      return y1 < y2;
    },

    activeFirstBatchForm() {
      if (this.assignmentToShare.batches.length != 0) {
        this.showForm(this.assignmentToShare.batches[0].batch_id);
      }
    },

    initializeFormData() {
      this.assignmentToShare.batches.forEach((element) => {
        this.$set(this.dialogMap, "share" + element.batch_id, false);
        this.$set(this.dialogMap, "due" + element.batch_id, false);
        if (element.shared) {
          this.$set(this.sharePanelFormData, "form" + element.batch_id, {
            share_date: element.share_date,
            due_date: element.due_date,
            share_date_for_view: this.formatDate(element.share_date),
            due_date_for_view: this.formatDate(element.due_date),
            total_score: element.total_score,
            is_share: true,
            should_highlight: true,
            batch_id: element.batch_id,
            course_id: element.course_id,
            object_id: element.object_id,
            object_type: element.object_type,
            title: element.title,
            is_clicked: false,
            is_public: element.publicly_available,
            is_static: element.static_content,
          });
        } else {
          this.$set(this.sharePanelFormData, "form" + element.batch_id, {
            share_date: "",
            due_date: "",
            share_date_for_view: "",
            due_date_for_view: "",
            total_score: "",
            is_share: false,
            should_highlight: false,
            batch_id: element.batch_id,
            course_id: "",
            object_id: "",
            object_type: "",
            title: "",
            is_clicked: false,
            is_public: false,
            is_static: false,
          });
        }
      });
    },

    showInputDateField(id) {
      this.$set(this.dialogMap, id, true);
    },

    isAllFieldVerified(id) {
      if (this.sharePanelFormData["form" + id].is_static) {
        if (
          this.sharePanelFormData["form" + id].share_date_for_view &&
          this.sharePanelFormData["form" + id].total_score
        ) {
          this.sharePanelFormData["form" + id].is_share = true;
          return true;
        }
        this.sharePanelFormData["form" + id].is_share = false;
        return false;
      } else {
        if (
          this.sharePanelFormData["form" + id].share_date_for_view &&
          this.sharePanelFormData["form" + id].due_date_for_view &&
          this.sharePanelFormData["form" + id].total_score &&
          this.isBefore(
            this.sharePanelFormData["form" + id].share_date_for_view,
            this.sharePanelFormData["form" + id].due_date_for_view
          )
        ) {
          this.sharePanelFormData["form" + id].is_share = true;
          return true;
        }
        this.sharePanelFormData["form" + id].is_share = false;
        return false;
      }
    },

    isDateFieldEmpty(id) {
      if (this.sharePanelFormData("form" + id).is_static) {
        if (this.sharePanelFormData["form" + id].share_date) return false;
        return true;
      } else
        this.sharePanelFormData["form" + id].due_date &&
          this.sharePanelFormData["form" + id].share_date;
      return false;
    },

    shouldEnableShare(id) {
      if (this.isAllFieldVerified(id)) {
        this.sharePanelFormData["form" + id].is_share = true;
      } else {
        this.sharePanelFormData["form" + id].is_share = false;
      }
    },

    hideInputDateField(id, index, token) {
      this.$set(this.dialogMap, token + id, false);
      if (token == "share") {
        this.$set(
          this.sharePanelFormData["form" + id],
          "share_date_for_view",
          this.formatDate(this.sharePanelFormData["form" + id].share_date)
        );
      } else {
        this.$set(
          this.sharePanelFormData["form" + id],
          "due_date_for_view",
          this.formatDate(this.sharePanelFormData["form" + id].due_date)
        );
      }

      if (!this.isDateFieldEmpty(id)) {
        if (
          !this.sharePanelFormData["form" + id].is_static &&
          !this.isBefore(
            this.sharePanelFormData["form" + id].share_date_for_view,
            this.sharePanelFormData["form" + id].due_date_for_view
          )
        ) {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName("mk-share-panel-alert")[0];
          alert.style = "visibility:visible;";
        } else {
          let form = document.getElementById(id);
          let alert = form.getElementsByClassName("mk-share-panel-alert")[0];
          alert.style = "visibility:hidden;";
        }
      }

      this.shouldEnableShare(id);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    closeSharePanel() {
      this.$emit("closePanel");
    },

    formatDateToSend(inputDate) {
      if (!inputDate) return null;
      const [day, month, year] = inputDate.split("/");
      return `${year}-${month}-${day}`;
    },

    async organizeDataAndSend() {
      this.finalFormData = {};
      for (var key in this.sharePanelFormData) {
        if (this.sharePanelFormData[key].is_share) {
          if (this.sharePanelFormData[key].is_static) {
            this.$set(this.finalFormData, key, {
            share_date: this.formatDateToSend(
              this.sharePanelFormData[key].share_date_for_view
            ),
            total_score: this.sharePanelFormData[key].total_score,
            batch_id: this.sharePanelFormData[key].batch_id,
            publicly_available: this.sharePanelFormData[key].is_public,
            static_content: this.sharePanelFormData[key].is_static,
          });
          }else{
            this.$set(this.finalFormData, key, {
            share_date: this.formatDateToSend(
              this.sharePanelFormData[key].share_date_for_view
            ),
            due_date: this.formatDateToSend(
              this.sharePanelFormData[key].due_date_for_view
            ),
            total_score: this.sharePanelFormData[key].total_score,
            batch_id: this.sharePanelFormData[key].batch_id,
            publicly_available: this.sharePanelFormData[key].is_public,
            static_content: this.sharePanelFormData[key].is_static,
          });
          }
        }
      }
      this.$emit("shareAssignment", this.finalFormData);
    },

    showForm(id) {
      let target_form_element = document.getElementById(id);
      if (this.current_form_element != undefined) {
        document.getElementById(this.current_form_element).style =
          "display:none";
        if (
          this.sharePanelFormData["form" + this.current_form_element].is_share
        ) {
          this.sharePanelFormData[
            "form" + this.current_form_element
          ].should_highlight = true;
          this.$set(
            this.sharePanelFormData["form" + this.current_form_element],
            "is_clicked",
            false
          );
        } else {
          this.sharePanelFormData[
            "form" + this.current_form_element
          ].should_highlight = false;
          this.$set(
            this.sharePanelFormData["form" + this.current_form_element],
            "is_clicked",
            false
          );
        }
      }

      target_form_element.style = "display:block";
      this.dialog = true;
      this.$set(this.sharePanelFormData["form" + id], "should_highlight", true);
      this.$set(this.sharePanelFormData["form" + id], "is_clicked", true);
      this.current_form_element = id;
    },

    //Can not catch this classes using css due to unknown reasons.that is why we used JavaScript to solve this problem.

    setSmallScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach((element) => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:45px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:45px;";
        element.style = "font-size:12px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:22px;";
        }
      });
      return "520px";
    },

    setMediumScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach((element) => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:50px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:50px;";
        element.style = "font-size:14px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:25px;";
        }
      });
      return "870px";
    },

    setLargeScreenStyle() {
      let text_field = document.getElementsByClassName(
        "mk-date-input-text-field"
      );
      Array.from(text_field).forEach((element) => {
        element.getElementsByTagName("fieldset")[0].style =
          "border-radius:0px !important; height:60px;";
        element.getElementsByClassName("v-text-field__slot")[0].style =
          "height:60px;";
        element.style = "font-size:18px !important;";
        let claendar = element.getElementsByTagName("i");
        if (claendar.length > 0) {
          claendar[0].style = "font-size:32px;";
        }
      });
      return "1198px";
    },
  },
};
</script>

<style scoped lang="scss">
@import "#ecf/assignment/styles/AssignmentSharePanel.scss";
</style>
