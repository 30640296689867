<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        ><PageTitleSearchCreate
          :page-title="!$language ? 'Assignments' : 'অ্যাসাইনমেন্ট সমূহ'"
          :placeholder="
            !$language
              ? 'Search By Assignment Name'
              : 'অ্যাসাইনমেন্ট এর নাম দিয়ে সার্চ করুন'
          "
          :create-btn="
            !$language ? 'Create Assignment' : 'অ্যাসাইনমেন্ট তৈরি করুন'
          "
          @createEvent="createAssignment()"
        ></PageTitleSearchCreate
      ></v-col>
      <template v-if="fetchingContent">
        <div id="loading-wrapper">
          <div id="loading-text">LOADING</div>
          <div id="loading-content"></div>
        </div>
      </template>
      <no-content-component
        v-else-if="noContentCondition"
        :message="noContentMessage"
      ></no-content-component>
      <v-col
        v-else
        v-for="(assignment, i) in assignments"
        :key="i"
        xl="4"
        lg="6"
        md="12"
        sm="12"
        class="m-4"
      >
        <MAssignmentListItem
          :assignment="assignment"
          @share="shareAssignment(i)"
          @download="download(assignment.object_id)"
          @delete="showDeleteBatchDialog"
        ></MAssignmentListItem>
      </v-col>
      <v-container v-if="shareAssignmentPanel">
        <AssignmentSharePanel
          :key="share_panel_key"
          :assignment-to-share="assignmentToShare"
          :sharing="sharing[assignmentToShare.object_id]"
          :share-panel="shareAssignmentPanel"
          :should_show_loading_icon="should_show_loading_icon"
          @closePanel="closeAssignmentPanel"
          @shareAssignment="submitFormData"
        ></AssignmentSharePanel>
      </v-container>
    </v-row>
    <v-dialog v-model="upload_dialog_visible" width="600px">
      <m-coursework-upload
        :on-cancel="hideUploadDialog"
        :on-save="uploadCoursework"
        :saving="saving"
      ></m-coursework-upload>
    </v-dialog>
    <dialog-delete
      v-model="assignmentDeleteDialog"
      :item="assignmentToDelete"
      :item-text="!$language ? 'Assignment' : 'অ্যাসাইনমেন্ট'"
      :deleting="deleting"
      @confirm-remove="removeAssignment"
    ></dialog-delete>
  </v-container>
</template>

<script>
import _ from "lodash";
import courseworkService from "#ecf/assignment/services/CourseworkService";
import batchService from "#ecf/batch/services/BatchService";
import MCourseworkUpload from "@ecf/routes/assignment/AssignmentUpload";
import PageTitleSearchCreate from "@ecf/global/components/PageTitleSearchCreate";
import MAssignmentListItem from "#ecf/assignment/components/MAssignmentListItem";
import AssignmentSharePanel from "#ecf/assignment/components/AssignmentSharePanel";
import DialogDelete from "@ecf/global/components/DialogDelete";
import { delayPromise } from "/global/utils/helpers";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "AssignmentListPage",
  components: {
    DialogDelete,
    AssignmentSharePanel,
    MAssignmentListItem,
    PageTitleSearchCreate,
    MCourseworkUpload,
    NoContentComponent
  },
  props: {
    courseId: String
  },
  data() {
    return {
      fetchingContent: false,
      upload_dialog_visible: false,
      share_dialog_visible: {},
      saving: false,
      batches: [],
      courseworksData: [],
      shareAssignmentPanel: false,
      assignmentsData: [],
      assignment_shares: {},
      assignmentToShare: undefined,
      share_idx: undefined,
      sharing: {},
      unsharing: {},
      assignmentDeleteDialog: false,
      assignmentToDelete: undefined,
      deleting: false,
      should_show_loading_icon: false,
      downloading: false,
    };
  },
  computed: {
    assignments() {
      return this.assignmentsData.map(a => {
        const shares = this.assignment_shares[a.object_id]
          ? this.assignment_shares[a.object_id]
          : {};
        const batches = this.batches.map(b => {
          if (shares[b.batch_id]) {
            return Object.assign({}, shares[b.batch_id], {
              batch_title: b.batch_title,
              shared: true
            });
          } else {
            return {
              batch_title: b.batch_title,
              batch_id: b.batch_id,
              shared: false
            };
          }
        });
        this.$set(a, "batches", batches);
        const shared_batches = a.batches
          .filter(b => b.shared)
          .map(b => b.batch_title);
        this.$set(a, "shared_batches", shared_batches);
        return a;
      });
    },
    share_panel_key() {
      if (!this.assignmentToShare) {
        return "undefined";
      } else {
        return (
          this.assignmentToShare.object_id +
          "#" +
          this.assignmentToShare.batches.map(b => b.shared + "").join("#")
        );
      }
    },
    noContentCondition() {
      return this.assignments?.length == 0;
    },
    noContentMessage() {
      return !this.$language
        ? "You currently have no assignments"
        : "বর্তমানে আপনার কোনো অ্যাসাইন্মেন্ট নেই";
    },
  },
  async created() {
    this.fetchingContent = true;
    await this.fetchBatches();
    await this.fetchCourseworks();
    this.fetchingContent = false;
  },
  methods: {
    createAssignment() {
      this.upload_dialog_visible = true;
    },
    closeAssignmentPanel() {
      this.shareAssignmentPanel = false;
    },
    async fetchCourseworks() {

      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.assignmentsData = await courseworkService.find(this.courseId);
        this.assignmentsData.forEach(a => {
          courseworkService
            .getSharedBatches(a.object_id, this.courseId)
            .then(shares => {
              const share_map = _.fromPairs(shares.map(s => [s.batch_id, s]));
              this.$set(this.assignment_shares, a.object_id, share_map);
            });
        });
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      }
    },
    async fetchBatches() {
      try {
        this.batches = await batchService.find(this.courseId);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e.message)
        ]);
      }
    },
    async uploadCoursework(courseworkData) {
      if(this.saving) return;
      try {
        this.saving = true;
        await courseworkService.save(this.courseId, courseworkData);
        
        await delayPromise(3000);
        await this.fetchCourseworks();
        this.hideUploadDialog();
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      } finally {
        this.saving = false;
      }
    },
    async download(coursework_id) {
      if(this.downloading) return;
      try {
        this.downloading = true;
        const assignment = await courseworkService.get(
          coursework_id,
          this.courseId
        );
        const url = assignment.url;
        window.open(url);
      } catch (e) {
        this.$root.$emit("alert", [
          undefined,
          JSON.stringify(e.response ? e.response.data : e)
        ]);
      } finally {
        this.downloading = false;
      }
    },
    hideUploadDialog() {
      this.upload_dialog_visible = false;
    },

    async submitFormData(formData) {
      if(this.should_show_loading_icon) return;
      try {
        let finalFormData = [];
        this.should_show_loading_icon = true;
        if (Object.keys(formData).length > 0) {
          for (var formKey in formData) {
            this.$set(formData[formKey], "course_id", this.courseId);
            this.$set(formData[formKey], "object_type", "assignment");
            this.$set(
              formData[formKey],
              "object_id",
              this.assignmentToShare.object_id
            );
            this.$set(
              formData[formKey],
              "partner_code",
              this.$route.params.partner_code
            );
            finalFormData.push(formData[formKey]);
          }
          await courseworkService.shareMultiple(finalFormData);
          await this.fetchCourseworks();
        } else {
          this.$set(formData, "course_id", this.courseId);
          this.$set(formData, "object_type", "assignment");
          this.$set(formData, "object_id", this.assignmentToShare.object_id);
          this.$set(formData, "partner_code", this.$route.params.partner_code);
          await courseworkService.deleteMultiple(formData);
          await this.fetchCourseworks();
        }
        this.shareAssignmentPanel = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.should_show_loading_icon = false;
      }
    },

    shareAssignment(idx) {
      this.shareAssignmentPanel = true;
      this.share_idx = idx;
      this.assignmentToShare = this.assignments[idx];
    },
    showDeleteBatchDialog(assignment) {
      this.assignmentDeleteDialog = true;
      this.assignmentToDelete = assignment;
    },
    async removeAssignment() {
      if(this.deleting) return;
      try {
        this.deleting = true;
        await courseworkService.delete(this.assignmentToDelete, this.courseId);
        await this.fetchCourseworks();
        this.hideDeleteDialog();
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
    hideDeleteDialog() {
      this.assignmentDeleteDialog = false;
    }
  }
};
</script>
