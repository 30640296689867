<template>
  <v-card class="mk-card-radius pa-4">
    <v-card-title class="title mk-highlight-text mb-5">{{
      !$language ? "Upload Assignment" : "এসাইনমেন্ট আপলোড করুন"
    }}</v-card-title>
    <v-form
      ref="form"
      v-model="allValid"
      autocomplete="off"
      @submit.prevent="() => {}"
    >
      <v-card-text>
        <v-text-field
          v-model.trim="formData.title"
          outlined
          :label="!$language ? 'Title' : 'টাইটেল'"
          :rules="rules.title"
          :placeholder="
            !$language
              ? 'Enter your assignment title here...'
              : 'এখানে আপনার এসাইনমেন্টের টাইটেল লিখুন...'
          "
        ></v-text-field>
        <!--<v-text-field
        v-model.trim="formData.object_name"
        label="File Name"
      ></v-text-field>-->
        <v-file-input
          v-model="formData.file"
          outlined
          show-size
          chips
          :label="!$language ? 'File Upload' : 'ফাইল আপলোড'"
          :placeholder="
            !$language
              ? 'Upload the file here...'
              : 'এখানে আপনার ফাইল আপলোড করুন'
          "
          :rules="rules.fileRules"
          @keypress.enter.native.prevent
        ></v-file-input>
        <v-checkbox
          v-model="formData.isDownloadable"
          :label="
            !$language
              ? 'Allow students to download the content'
              : 'শিক্ষার্থীদের কন্টেন্ট ডাউনলোড করার অনুমতি দিন'
          "
        ></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          outlined
          color="red accent-3"
          class="btn-outlined"
          @click="cancel()"
        >
          <v-icon left>cancel</v-icon>
          {{ !$language ? "Cancel" : "বাতিল করুন" }}
        </v-btn>
        <v-btn
          depressed
          rounded
          color="primary"
          class="ml-2 btn-outlined"
          :loading="saving"
          @click="upload()"
        >
          <v-icon left>arrow_circle_up</v-icon>
          {{ !$language ? "Upload" : "আপলোড করুন" }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from "lodash";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "AssignmentUpload",
  props: {
    onCancel: Function,
    onSave: Function,
    saving: Boolean,
  },
  mixins: [FieldValidations],
  data() {
    return {
      formData: {
        title: undefined,
        object_name: undefined,
        file: undefined,
        isDownloadable: false,
      },
      allValid: true,
      validating: false,
    };
  },
  computed: {
    rules() {
      return {
        title: !this.validating
          ? []
          : [
              (v) => this.required(v?.trim()),
              (v) => this.minLength(v?.trim(), 5),
              (v) => this.maxLength(v?.trim(), 300),
            ],
        fileRules: !this.validating
          ? []
          : [
              (v) => this.required(v), // does not work without this syntax
              (v) => this.fileSizeLessThanOrEqual(v, 10 * 1024 * 1024),
            ],
      };
    },
  },
  watch: {
    validating: {
      handler(value) {
        if (value) {
          this.$refs.form.validate();
        }
      },
    },
    formData: {
      deep: true,
      handler() {
        this.validating = false;
        new Promise((res) => setTimeout(res, 50)).then(() => {});
      },
    },
  },
  methods: {
    async upload() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      this.validating = true;
      await new Promise((res) => setTimeout(res, 50));
      await this.save();
    },
    async save() {
      if (!this.allValid) {
        return;
      }
      await this.onSave(_.omitBy(this.formData, _.isUndefined));
      this.$refs.form.reset();
    },
    cancel() {
      this.onCancel();
      this.$refs.form.reset();
    },
  },
};
</script>
