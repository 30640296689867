var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-app",
        [
          _c(
            "v-dialog",
            {
              staticClass: "custom-font-family",
              attrs: {
                width: _vm.$vuetify.breakpoint.mdAndDown
                  ? _vm.setSmallScreenStyle()
                  : _vm.$vuetify.breakpoint.lgOnly
                  ? _vm.setMediumScreenStyle()
                  : _vm.setLargeScreenStyle(),
                persistent: "",
              },
              model: {
                value: _vm.sharePanel,
                callback: function ($$v) {
                  _vm.sharePanel = $$v
                },
                expression: "sharePanel",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "mk-share-panel-header-card" },
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0 mk-share-panel-header-section" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "ma-0 pr-0 mk-share-panel-header-title-section",
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "pa-0 ma-0" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pa-0 ma-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "pa-0 ma-0 mk-share-panel-title text-truncate",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.assignmentToShare.title) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "ma-0 pa-0" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("submission-alert-message", {
                            ref: "banner",
                            attrs: { message: _vm.bannerMessage },
                            model: {
                              value: _vm.showBanner,
                              callback: function ($$v) {
                                _vm.showBanner = $$v
                              },
                              expression: "showBanner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0 mk-batch-header-title-section" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { cols: "12", lg: "4", xl: "4" },
                        },
                        [
                          _c("h1", { staticClass: "pr-0 py-0 ma-0" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$language
                                    ? "List of Batches"
                                    : "ব্যাচ লিস্ট"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("v-col", {
                        staticClass: "pa-0 ma-0",
                        attrs: { cols: "12", lg: "8", xl: "8" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-0 ma-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0",
                          attrs: { cols: "5", lg: "4", xl: "4" },
                        },
                        [
                          _vm.noContentCondition
                            ? _c("no-content-component", {
                                attrs: { message: _vm.noContentMessage },
                              })
                            : _c(
                                "v-item-group",
                                { attrs: { multiple: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "mk-share-batch-section",
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass:
                                                "pa-0 mr-0 my-0 mk-batch-list-item",
                                            },
                                            _vm._l(
                                              _vm.assignmentToShare.batches,
                                              function (batch) {
                                                return _c(
                                                  "v-col",
                                                  {
                                                    key: batch.batch_id,
                                                    staticClass:
                                                      "px-0 pb-0 mr-0 my-0",
                                                    attrs: {
                                                      id:
                                                        "title" +
                                                        batch.batch_id,
                                                      cols: "12",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showForm(
                                                          batch.batch_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-item",
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center white--text mk-share-batch-card",
                                                            staticStyle: {
                                                              border:
                                                                "thin solid rgba(0, 0, 0, 0.12) !important",
                                                            },
                                                            attrs: {
                                                              outlined: "",
                                                              flat: "",
                                                              color: _vm
                                                                .sharePanelFormData[
                                                                "form" +
                                                                  batch.batch_id
                                                              ].is_clicked
                                                                ? "#BBDEFB"
                                                                : _vm
                                                                    .sharePanelFormData[
                                                                    "form" +
                                                                      batch.batch_id
                                                                  ]
                                                                    .should_highlight
                                                                ? "primary"
                                                                : "white",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-scroll-y-transition",
                                                              [
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ].is_clicked
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm
                                                                      .sharePanelFormData[
                                                                      "form" +
                                                                        batch.batch_id
                                                                    ]
                                                                      .should_highlight
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "flex-grow-1 text-left black--text text-truncate mk-share-panel-batch-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              batch.batch_title
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 mk-share-panel-form-section",
                          attrs: { cols: "7", lg: "8", xl: "8" },
                        },
                        _vm._l(
                          _vm.assignmentToShare.batches,
                          function (batch, index) {
                            return _c(
                              "v-row",
                              {
                                key: batch.batch_id,
                                staticClass: "pa-0 ma-0 mk-share-panel-form",
                                attrs: { id: batch.batch_id },
                              },
                              [
                                _c(
                                  "v-form",
                                  {
                                    ref: "form",
                                    refInFor: true,
                                    staticClass: "pa-0 ma-0",
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.submit.apply(null, arguments)
                                      },
                                    },
                                    model: {
                                      value: _vm.valid,
                                      callback: function ($$v) {
                                        _vm.valid = $$v
                                      },
                                      expression: "valid",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "pa-0 ma-0 mk-share-panel-form-input-date",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pa-0 ma-0",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mk-share-panel-alert",
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "Share Date Must Be Before Deadline."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pl-0 py-0 ma-0 mk-publish-date-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                      "max-width": "290px",
                                                      "min-width": "290px",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  {
                                                                    ref: "shareDate",
                                                                    refInFor: true,
                                                                    staticClass:
                                                                      "mk-date-input-text-field",
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      label:
                                                                        !_vm.$language
                                                                          ? "Share Date"
                                                                          : "তারিখে শেয়ার করুন",
                                                                      placeholder:
                                                                        !_vm.$language
                                                                          ? "Select Date"
                                                                          : "তারিখ নির্বাচন করুন",
                                                                      "append-icon":
                                                                        "mdi-calendar-month-outline",
                                                                      dense:
                                                                        _vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .mdAndDown ||
                                                                        _vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgOnly,
                                                                      "rounded-0":
                                                                        "",
                                                                      rules: [
                                                                        _vm.required,
                                                                      ],
                                                                      hint: "DD/MM/YYYY format",
                                                                      clearable:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .sharePanelFormData[
                                                                          "form" +
                                                                            batch.batch_id
                                                                        ]
                                                                          .share_date_for_view,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ],
                                                                            "share_date_for_view",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .share_date_for_view\n                            ",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.dialogMap[
                                                          "share" +
                                                            batch.batch_id
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogMap,
                                                          "share" +
                                                            batch.batch_id,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogMap['share' + batch.batch_id]",
                                                    },
                                                  },
                                                  [
                                                    _c("v-date-picker", {
                                                      attrs: {
                                                        "no-title": "",
                                                        scrollable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.hideInputDateField(
                                                            batch.batch_id,
                                                            index,
                                                            "share"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].share_date,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ],
                                                            "share_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .share_date\n                          ",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pr-0 py-0 ma-0 mk-due-date-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                      disabled: _vm
                                                        .sharePanelFormData[
                                                        "form" + batch.batch_id
                                                      ].is_static
                                                        ? true
                                                        : false,
                                                      "max-width": "290px",
                                                      "min-width": "290px",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  {
                                                                    ref: "dueDate",
                                                                    refInFor: true,
                                                                    staticClass:
                                                                      "mk-date-input-text-field",
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      label:
                                                                        !_vm.$language
                                                                          ? "Submission Deadline"
                                                                          : "জমা দেওয়ার সময়সীমা",
                                                                      placeholder:
                                                                        !_vm.$language
                                                                          ? "Select Date"
                                                                          : "তারিখ নির্বাচন করুন",
                                                                      "append-icon":
                                                                        "mdi-calendar-month-outline",
                                                                      rules: _vm
                                                                        .sharePanelFormData[
                                                                        "form" +
                                                                          batch.batch_id
                                                                      ]
                                                                        .is_static
                                                                        ? []
                                                                        : [
                                                                            _vm.required,
                                                                          ],
                                                                      hint: "DD/MM/YYYY format",
                                                                      dense:
                                                                        _vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .mdAndDown ||
                                                                        _vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .lgOnly,
                                                                      clearable:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .sharePanelFormData[
                                                                          "form" +
                                                                            batch.batch_id
                                                                        ]
                                                                          .due_date_for_view,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .sharePanelFormData[
                                                                              "form" +
                                                                                batch.batch_id
                                                                            ],
                                                                            "due_date_for_view",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "\n                              sharePanelFormData['form' + batch.batch_id]\n                                .due_date_for_view\n                            ",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.dialogMap[
                                                          "due" + batch.batch_id
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.dialogMap,
                                                          "due" +
                                                            batch.batch_id,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "dialogMap['due' + batch.batch_id]",
                                                    },
                                                  },
                                                  [
                                                    _c("v-date-picker", {
                                                      attrs: {
                                                        "no-title": "",
                                                        scrollable: "",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.hideInputDateField(
                                                            batch.batch_id,
                                                            index,
                                                            "due"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].due_date,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ],
                                                            "due_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .due_date\n                          ",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pl-0 py-0 ma-0 mk-total-mark-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "mk-date-input-text-field",
                                                  attrs: {
                                                    outlined: "",
                                                    label: !_vm.$language
                                                      ? "Mark"
                                                      : "মার্ক",
                                                    placeholder: !_vm.$language
                                                      ? "ex:100"
                                                      : "উদা: ১০০",
                                                    dense:
                                                      _vm.$vuetify.breakpoint
                                                        .mdAndDown ||
                                                      _vm.$vuetify.breakpoint
                                                        .lgOnly,
                                                    rules: [_vm.required],
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.shouldEnableShare(
                                                        batch.batch_id
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.sharePanelFormData[
                                                        "form" + batch.batch_id
                                                      ].total_score,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.sharePanelFormData[
                                                          "form" +
                                                            batch.batch_id
                                                        ],
                                                        "total_score",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "\n                          sharePanelFormData['form' + batch.batch_id]\n                            .total_score\n                        ",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pr-0 py-0 ma-0 mk-share-btn-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "mk-share-switch",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_share,
                                                        expression:
                                                          "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_share\n                          ",
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "checkbox",
                                                      disabled:
                                                        !_vm.isAllFieldVerified(
                                                          batch.batch_id
                                                        ),
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.sharePanelFormData[
                                                          "form" +
                                                            batch.batch_id
                                                        ].is_share
                                                      )
                                                        ? _vm._i(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_share,
                                                            null
                                                          ) > -1
                                                        : _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_share,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_share,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_share",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_share",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ],
                                                            "is_share",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("div", {
                                                    staticClass:
                                                      "mk-share-slider",
                                                  }),
                                                  _c("div", {
                                                    staticClass: "text",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pl-0 py-0 ma-0 mk-total-mark-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _vm._v(
                                                  " Is it available publicly? "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pr-0 py-0 ma-0 mk-share-btn-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "mk-share-switch",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_public,
                                                        expression:
                                                          "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_public\n                          ",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.sharePanelFormData[
                                                          "form" +
                                                            batch.batch_id
                                                        ].is_public
                                                      )
                                                        ? _vm._i(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_public,
                                                            null
                                                          ) > -1
                                                        : _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_public,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_public,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_public",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_public",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ],
                                                            "is_public",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("div", {
                                                    staticClass:
                                                      "mk-share-slider",
                                                  }),
                                                  _c("div", {
                                                    staticClass: "public-text",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-row",
                                      {
                                        staticClass:
                                          "px-0 pb-0 ma-0 mk-share-panel-form-input-mark-share-btn",
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pl-0 py-0 ma-0 mk-total-mark-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-center",
                                              },
                                              [
                                                _vm._v(
                                                  " Is it shared for a limited time? "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "pr-0 py-0 ma-0 mk-share-btn-section",
                                            attrs: {
                                              cols: "12",
                                              lg: "6",
                                              xl: "6",
                                            },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "mk-share-switch",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_static,
                                                        expression:
                                                          "\n                            sharePanelFormData['form' + batch.batch_id]\n                              .is_static\n                          ",
                                                      },
                                                    ],
                                                    attrs: { type: "checkbox" },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        _vm.sharePanelFormData[
                                                          "form" +
                                                            batch.batch_id
                                                        ].is_static
                                                      )
                                                        ? _vm._i(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_static,
                                                            null
                                                          ) > -1
                                                        : _vm
                                                            .sharePanelFormData[
                                                            "form" +
                                                              batch.batch_id
                                                          ].is_static,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$a =
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ].is_static,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_static",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm
                                                                  .sharePanelFormData[
                                                                  "form" +
                                                                    batch.batch_id
                                                                ],
                                                                "is_static",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm
                                                              .sharePanelFormData[
                                                              "form" +
                                                                batch.batch_id
                                                            ],
                                                            "is_static",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                    },
                                                  }),
                                                  _c("div", {
                                                    staticClass:
                                                      "mk-share-slider",
                                                  }),
                                                  _c("div", {
                                                    staticClass: "static-text",
                                                  }),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mk-share-cancle-done-btn-section" },
                    [
                      _c("v-row", { staticClass: "pa-0 ma-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "pl-0 py-0 ma-0 mk-share-panel-cancel",
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-font-family",
                                attrs: { depressed: "", tile: "", plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeSharePanel()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language ? "Cancel" : "বাতিল করুন"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "pa-0 mx-0 mx-0 mk-share-panel-done" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "custom-font-family",
                                attrs: {
                                  depressed: "",
                                  tile: "",
                                  plain: "",
                                  loading: _vm.should_show_loading_icon,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.organizeDataAndSend()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.$language ? "Done" : "সম্পন্ন করুন"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }